import {BASE_URL,DEFAULT_KEY} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';
import axios from 'axios';

export default class AppInfoservice{

    key =localStorage.getItem('apiKey')
    async Appinfo(){
        
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'common/app_info', {     //url
                method: 'GET',                 //method
                headers : {                     //passing header 
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key?this.key:DEFAULT_KEY   // API key
                },
                // body: JSON.stringify(userData)  //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    check_token(KeyData){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'common/check_token', {     //url
                method: 'POST',                 //method
                headers : {                     //passing header 
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key?this.key:DEFAULT_KEY   // API key
                },
                body: JSON.stringify(KeyData)  //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    get_layout(){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'home/app_layout', {     //url
                method: 'GET',                 //method
                headers : {                     //passing header 
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,   // API key
                    'access-token'  : UserStore.key
                },
                // body: JSON.stringify(KeyData)  //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    //get the notification 
    async getNotification(){
        const response = await fetch(BASE_URL+'home/notifications', {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const newsData = await response.json();
        return newsData ;
    }

    async getCountry(){
        const response = await fetch(`${BASE_URL}common/getAllCountry`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const result = await response.json();
        return result;
    }


    addNewDemoVisitor(formData){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'common/add_new_demo_visitor', {     //url
                method: 'POST',                 //method
                headers : {                     //passing header
                  //  'Accept'        : 'application/json',
                  //  'Content-Type'  : 'multipart/form-data',
                    'api-key'       : this.key   // API key
                },
                body: formData  //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }


    VerifyOtp(formData){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'common/verifyotp', {     //url
                method: 'POST',                 //method
                headers : {                     //passing header
                    // 'Accept'        : 'application/json',
                    // 'Content-Type'  : 'application/json',
                    'api-key'       : this.key   // API key
                },
                body: formData //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    ResendOtp(formData){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'common/resendotp', {     //url
                method: 'POST',                 //method
                headers : {                     //passing header
                    // 'Accept'        : 'application/json',
                    // 'Content-Type'  : 'application/json',
                    'api-key'       : this.key   // API key
                },
                body: formData  //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    checkDemoUsers(){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'home/chckeDemo', {     //url
                method: 'GET',                 //method
                headers : {                     //passing header
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'access-token'  : UserStore.key ,
                    'api-key'       : this.key   // API key
                },
           
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }


}



