import {React ,useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Form} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import PaymentMethods from '../common/PaymentMethods';
import style from './registration.module.scss';
import {Paneltilte} from './';

import _ from "lodash";

function Payment(props) {
    const {t} = useTranslation()
    const currency = useSelector(state=> state.curr);

    var method = _.cloneDeep(props.data);

    const [payment,setPayment] = useState({
        methods : method
    })

        if(props.contactInfo == "customer"){
                      for( var i = 0; i < payment.methods?.fields.length; i++){ 
                        if (payment.methods.fields[i].code === "freejoin") { 
                            payment.methods.fields.splice(i, 1); 
                            i--; 
                        }
                  }
                //   setPayment(prev=>({
                //     ...prev,
                //     methods : payment.methods
                //   }))
                }
                  else {
                    // setMethod({
                    //   data: state.PaymentMethods,
                    // }); 
                    for( var i = 0; i < payment.methods.fields.length; i++){ 
                   if (payment.methods.fields[i].code !== "freejoin") { 
                    payment.methods.fields.splice(i, 1); 
                    i--; 
        
                    
                       }
                
          }
        //   setPayment(prev=>({
        //     ...prev,
        //     methods : payment.methods
        //   }))
                  }

      



    let amount= parseInt(props.productAmount)+parseInt(props.data.registrationFee);
    return (
        <div className={style.paymentMethod}>
            <Paneltilte 
                tilte="paymentType"
            />
        {    props.contactInfo == "customer" &&
            <div className={style.totalAmount}>
                <h4>{t('Common.totalAmount')} : {currency.currentCurr} {(amount * currency.value).toFixed(currency.precision)}</h4>
            </div>
        }





            <Form onSubmit={(e)=>{props.submit(e,'payment')}}>
                <PaymentMethods 
                    methods={payment.methods.fields}
                    selectedTab={1}
                    // prevButtonClick={props.prevButtonClick}
                    // methodChange={props.paymentMethodChange}
                    // username={props.userName}
                    // product={props.product}
                    amount={amount}
                    payment_type={props.payment_type}
                    {...props}
                />
            </Form>
        </div>
    )
}

export default Payment
