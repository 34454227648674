import React, { useEffect, useState ,useRef} from 'react';
import {Card,Row,Col,Form,Button,Alert,Table,Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import style from './profile.module.scss';
import ProfileService from '../../../service/profile';
import {DropDownBs,AddonNotification} from '../common';
function KycContent(props) {
    const service = new ProfileService();
    const {t} = useTranslation();
    const itemEls = useRef(new Array());
    const [state,setState] = useState({
        category   : [],
        selectedFile : [
            {
                file : ''
            }
        ],
        alert : {
            show : false,
            message : "",
            variant : ''
        },
        ImgPreview : {
            show : false,
            url : ''
        }
    });
    useEffect(()=>{
        console.log("00000",props)
        if(props.data.category && props.data.category.length>0){
          
            setState(prev=>({
                ...prev,
                category : state.category.length>0 ? state.category[0]:props.data.category[0].id
            }))
        }
       
    },[props.data])

 
   

    //change handler
    const changeHandler = (e) =>{
        let { name , value}  = e.target
        setState(prev=>({
            ...prev,
            [name] : value
        }))
    }
    //file change event 
    const onFileChange = (event,index) => {
        if(event.target.files[0]){
            let file = state.selectedFile;
            file[index] = event.target.files[0]
            setState(prev=>({
                ...prev, 
                selectedFile: file
            }));
        }
        
    };

    //file validation 
    const validation = (element) =>element.file === ''

    //upload kyc
    const uploadKyc = () =>{
        if(state.selectedFile.some(validation)){
            setState(prev=>({
                ...prev,
                alert : {
                    show : true,
                    variant : 'danger',
                    message : 'chooseFile'
                }
            }))
        }else if(state.category ===''){
            setState(prev=>({
                ...prev,
                alert : {
                    show : true,
                    variant : 'danger',
                    message : 'chooseCategory'
                }
            }))
        }else{
            let formData = new FormData();
            formData.append('category',state.category);
            state.selectedFile.forEach(element => {
                formData.append('id_proof[]',element);
            });
            service.uploadFile(formData).then((res)=>{
                if(res.status){
                    setState(prev=>({
                        ...prev,
                        alert : {
                            show : true,
                            variant : 'success',
                            message : 'idUploadSuccessfully'
                        }
                    }))
                    state.selectedFile.map((fileitams,index)=>{
                        itemEls.current[index].value = "";

                    })
                    setState(prev=>({
                        ...prev, 
                        selectedFile: [{
                            file : ''
                        }]
                        
                    }));
           
                    props.loadData();
                }else{
                    let message = ''
                    if(res.error.code === 1041){
                        message = 'idAlreadyExist'
                    }else if(res.error.code === 1024){
                        message = 'errorInUpload'
                    }else if(res.error.code ===1003){
                        message='chooseCategory'
                    }

                    setState(prev=>({
                        ...prev,
                        alert : {
                            show : true,
                            variant : 'danger',
                            message : message
                        }
                    }))
                }
            })
        }
    }

    //add file input file field 
    const addfileinput = ()=>{
        if(state.selectedFile.length<=1){
            setState(prev=>({
                ...prev,
                selectedFile: [
                    ...prev.selectedFile,
                    {
                        file : ''
                    }
                ]
            }));
        }else{
            let file = state.selectedFile;
            file.splice(-1);
            setState(prev=>({
                ...prev,
                selectedFile: file
            }));
        }
    }

    //close alert
    const closeAlert =() =>{
        setState(prev=>({
            ...prev,
            alert : {
                ...prev.alert,
                show : false
            }
        }))
    }

    //delete kyc files
    const deletefiles = (id) =>{
        if(id){
            if (window.confirm(t('validation.sureWantToDelete'))){
                let idData= {
                    id : id
                }
                service.deletefile(idData).then(res=>{
                    if(res.status){
                        setState(prev=>({
                            ...prev,
                            alert : {
                                show : true,
                                variant : 'success',
                                message : 'succussfullydeleted'
                            }
                        }))
                        props.loadData();
                    }
                })
            }
        }
    }

    //close modal 
    const handleClose =()=>{
        setState(prev=>({
            ...prev,
            ImgPreview : {
                ...prev.ImgPreview,
                show : false
            }
        }))
    }

    //show modal 
    const handleShow =(url) =>{
        setState(prev=>({
            ...prev,
            ImgPreview : {
                ...prev.ImgPreview,
                show : true,
                url : url
            }
        }))
    }
    return (
        <div className={style.MainContainer}>
            <AddonNotification />
            <div className={style.KycDetials}>
                <Card className={style.card}>
                    <Card.Body className={style.cardBody}>
                            <Alert variant={state.alert.variant} dismissible show={state.alert.show} onClose={closeAlert} >
                                {t('validation.'+state.alert.message)}
                            </Alert>
                        <Row>
                            <Col md={4}>
                                <DropDownBs
                                    label={t('Common.selectCategory')}
                                    DropdownProps={{
                                        name:'category',
                                        onChange:changeHandler
                                    }}
                                    options={{
                                        data : props.data.category,
                                        value:'id',
                                        label:'category'
                                    }}
                                />
                            </Col>
                            <Col md={4}>
                            <Form.Group>
                                <Form.Label>{t('Common.selectFile')}</Form.Label>
                                {
                                state.selectedFile.map((fileitams,index)=>(

                                        <Form.File onChange={e=>onFileChange(e,index)} key={index} ref={(element) => itemEls.current[index] = element} />
                                ))
                                }
                                <Form.Text>{t('Common.allowTypes')}</Form.Text>
                            </Form.Group>
                            </Col>
                            <Col md={3}>
                                <div className={style.formButtons}>
                                        <Button variant="primary" onClick={addfileinput}>
                                            {
                                                state.selectedFile.length===1?
                                                <i className="fa fa-plus-square"></i>:
                                                <i className="fa fa-minus-square"></i>
                                            }
                                        </Button>
                                    <Button variant="primary" onClick={uploadKyc}>
                                        {t('Button.upload')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className={style.card}>
                    <Card.Body className={style.cardBody}>
                        <legend>
                            <span>{t('Common.uploadedDocuments')}</span>
                        </legend>
                        {
                            props.data.id && props.data.id.length>0?
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{t('Common.documentName')}</th>
                                        <th>{t('Common.status')}</th>
                                        <th>{t('Common.documetFile')}</th>
                                        <th>{t('Common.action')}</th>
                                    </tr>
                                </thead>
                                <tbody> 
                                {
                                    props.data.id.map((items,index)=>{
                                        console.log(items.status)
                                        return <tr key={index}> 
                                            <td>{index+1}</td>
                                            <td>{items.category}</td>
                                            <td><span style={{textTransform: 'capitalize'}} className={`${style[items.status]} ${style.status}`}>{items.status}</span></td>
                                            <td>
                                                {
                                                    items.file_name.map((files,index1)=>(
                                                        <Button key={index1} className={style.docFile} onClick={()=>handleShow(files)}>
                                                            <img src={files} alt="" width={30} height={20}/>
                                                        </Button>
                                                    ))
                                                }
                                            </td>
                                            <td>
                                                <Button onClick={()=>deletefiles(items.id)} className={style.deletefiles}>
                                                    <i className="fa fa-trash-o"></i>
                                                </Button>
                                            </td>
                                        </tr>
                                        })
                                }
                                </tbody>
                            </Table>:
                            <h4 className={style.nodata}> {t('Common.noDataFound')}</h4>
                        }
                    </Card.Body>
                </Card>
                <Modal show={state.ImgPreview.show} onHide={handleClose} contentClassName={style.imgPreviewContent}>
                <Modal.Body >
                    <img src={state.ImgPreview.url} alt=""/>
                </Modal.Body>
                <Modal.Footer className={style.PreviewFooter}>
                    <Button variant="secondary" onClick={handleClose} className={style.closeButton}>
                        {t('Button.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
            </div>
        </div>
    )
}

export default KycContent
