// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "https://demo2.infinitemlmdemo.com/sean-grillo/backoffice/api/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL = "https://demo2.infinitemlmdemo.com/sean-grillo/uploads/images/";
// const BASE = "https://demo2.infinitemlmdemo.com/sean-grillo";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// // export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY ='545fddf1-2cd7-4c32-b9bf-a9045b2bcbb1'
// console.log(localStorage);
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY
// }

//LOCAL
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://192.168.10.52/admin/backoffice/api/";
// const IMG_URL = "http://192.168.10.52/admin/uploads/images/";
// const BASE = "http://192.168.10.52/admin/";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY = '545fddf1-2cd7-4c32-b9bf-a9045b2bcbb1';
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY
// }

//LIVE
import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME =companyName.companyName??'Company Name';
const BASE_URL = "https://competitiveadvantagenetwork.com/admin/backoffice/api/";
const IMG_URL = "https://competitiveadvantagenetwork.com/admin/uploads/images/";
const BASE = "https://competitiveadvantagenetwork.com/admin/";
const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
const DEFAULT_KEY = '545fddf1-2cd7-4c32-b9bf-a9045b2bcbb1';
export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE,
    DEFAULT_KEY
}
